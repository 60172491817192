import React, { createContext, useState, useEffect, useContext } from "react";
import { getUser } from "./api"; // Adjust the path if api.js is located elsewhere
import { fetcher2 } from "./api";

// Create the context
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwt");
    if (jwtToken) {
      // Assume there's an API endpoint to fetch the logged-in user's details
      getUser("me")
        .then((data) => {
          if (data.id) {
            setIsLoggedIn(true);
            setUserData(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
          // Optionally, you can also remove the jwt token from localStorage if the token is invalid.
        });
    }
    setIsLoading(false);
  }, []);

  const login = (data, jwtToken) => {
    localStorage.setItem("jwt", jwtToken);
    setIsLoggedIn(true);
    setUserData(data);
  };

  async function registerUser(
    username,
    email,
    password,
    firstName,
    lastName,
    streetName,
    houseNumber,
    postalCode,
    city,
    phoneNumber
  ) {
    const data = await fetcher2("auth/local/register", "POST", {
      username: email,
      email,
      password,
      firstName,
      lastName,
      streetName,
      houseNumber,
      postalCode,
      city,
      phoneNumber,
    });

    if (data.jwt) {
      localStorage.setItem("jwt", data.jwt);
      setIsLoggedIn(true);
      setUserData(data.user);
      return data.user;
    } else {
      throw new Error(data.message);
    }
  }

  useEffect(() => {}, [isLoggedIn, userData]);

  // Helper function to log out
  const logout = () => {
    localStorage.removeItem("jwt");
    setIsLoggedIn(false);
    setUserData(null);
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, userData, login, logout, isLoading, registerUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
