import { createTheme } from "@mui/material/styles";

export const shades = {
  primary: {
    100: "#3a435d", // Nieuwe primaire kleur van het voorbeeldbestand
    200: "#999999", // Kun je aanpassen als nodig
    300: "#666666", // Kun je aanpassen als nodig
    400: "#333333", // Kun je aanpassen als nodig
    500: "#000000",
    600: "#000000",
    700: "#000000",
    800: "#000000",
    900: "#000000",
  },
  secondary: {
    100: "#f9d1e5", // Je bestaande kleur
    200: "#f4a3cb", // Je bestaande kleur
    300: "#e3197e", // Accentkleur van het voorbeeldbestand
    400: "#e3197e", // Je bestaande kleur
    500: "#e3197e", // Je bestaande kleur
    600: "#b61465", // Je bestaande kleur
    700: "#880f4c", // Je bestaande kleur
    800: "#5b0a32", // Je bestaande kleur
    900: "#2d0519", // Je bestaande kleur
  },
  neutral: {
    100: "#070b1d", // Nieuwe achtergrondkleur van het voorbeeldbestand
    200: "#101425", // Variant achtergrondkleur van het voorbeeldbestand
    300: "#a4a5b8", // Secundaire tekstkleur van het voorbeeldbestand
    400: "#7E7D7D", // Je bestaande kleur
    500: "#cfcdcd", // Je bestaande kleur
    600: "#a6a4a4", // Je bestaande kleur
    700: "#7c7b7b", // Je bestaande kleur
    800: "#535252", // Je bestaande kleur
    900: "#292929", // Je bestaande kleur
  },
};

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: shades.primary[500],
    },
    secondary: {
      main: shades.secondary[500],
    },
    neutral: {
      dark: shades.neutral[700],
      main: shades.neutral[500],
      light: shades.neutral[100],
    },
    background: {
      default: "#ffffff",
      paper: "#F7F7F7",
    },
    text: {
      primary: shades.neutral[900],
      secondary: shades.neutral[700],
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "sans-serif"].join(","),
    fontSize: 11,
    h1: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 48,
    },
    h2: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 36,
    },
    h3: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 20,
    },
    h3title: {
      fontFamily: ["Monofett", "sans-serif"].join(","),
      fontSize: 40,
    },
    h5: {
      fontFamily: ["Monofett", "sans-serif"].join(","),
      fontSize: 20,
    },
    h4: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 14,
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: shades.primary[100], // Gebruik de primaire kleur uit het voorbeeld
    },
    secondary: {
      main: shades.secondary[300], // Gebruik de accentkleur uit het voorbeeld
    },
    neutral: {
      dark: shades.neutral[200], // Gebruik de variant achtergrondkleur uit het voorbeeld
      main: shades.neutral[300], // Gebruik de secundaire tekstkleur uit het voorbeeld
      light: shades.neutral[100], // Gebruik de achtergrondkleur uit het voorbeeld
    },
    background: {
      default: "#070b1d", // Gebruik de achtergrondkleur uit het voorbeeld
      paper: "#101425", // Gebruik de variant achtergrondkleur uit het voorbeeld
    },
    text: {
      primary: "#ffffff", // Primaire tekstkleur van het voorbeeld
      secondary: "#a4a5b8", // Secundaire tekstkleur van het voorbeeld
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "sans-serif"].join(","),
    fontSize: 11,
    h1: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 48,
    },
    h2: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 36,
    },
    h3: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 20,
    },
    h3title: {
      fontFamily: ["Monofett", "sans-serif"].join(","),
      fontSize: 40,
    },
    h5: {
      fontFamily: ["Monofett", "sans-serif"].join(","),
      fontSize: 20,
    },
    h4: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 14,
    },
  },
});
