// api.js
const BASE_URL = "https://api.vuurwerkbruchem.com";
const BASE_URL_METAPI = "https://api.vuurwerkbruchem.com/api";
const API_KEY = process.env.REACT_APP_STRAPI_KEY;

// Cache management
const CACHE_DURATION = 5 * 60 * 1000; // 5 minuten
const cache = new Map();

const isValidCache = (cacheEntry) => {
  return cacheEntry && Date.now() - cacheEntry.timestamp < CACHE_DURATION;
};

export const fetcher2 = async (
  endpoint,
  method = "GET",
  data = null,
  extraOptions = {}
) => {
  // Cache check alleen voor GET requests
  if (method === "GET") {
    const cacheKey = `${endpoint}-${JSON.stringify(extraOptions)}`;
    const cachedData = cache.get(cacheKey);

    if (isValidCache(cachedData)) {
      return cachedData.data;
    }
  }

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${API_KEY}`,
      ...extraOptions.headers,
    },
    ...extraOptions,
  };

  // Auth endpoint logica
  const authEndpoints = ["auth/local", "auth/local/register", "users"];
  const isAuthEndpoint = authEndpoints.some((authPath) =>
    endpoint.startsWith(authPath)
  );

  if (isAuthEndpoint) {
    delete options.headers.Authorization;
    const jwtToken = localStorage.getItem("jwt");
    if (jwtToken && !endpoint.includes("auth/local")) {
      options.headers.Authorization = `Bearer ${jwtToken}`;
    }
  }

  if (data) {
    options.body = JSON.stringify(data);
  }

  try {
    // First request to get initial data and pagination info
    const initialResponse = await fetch(
      `${BASE_URL_METAPI}/${endpoint}`,
      options
    );
    const initialResult = await initialResponse.json();

    if (initialResult.jwt) {
      localStorage.setItem("jwt", initialResult.jwt);
    }

    if (initialResult.error) {
      throw new Error(
        initialResult.error.message || "Er is een fout opgetreden"
      );
    }

    // Check voor paginatie
    if (
      !initialResult.meta?.pagination ||
      initialResult.meta.pagination.pageCount <= 1
    ) {
      if (method === "GET") {
        cache.set(endpoint, {
          data: initialResult,
          timestamp: Date.now(),
        });
      }
      return initialResult;
    }

    // Als er meerdere pagina's zijn, haal ze allemaal op
    const { pageCount } = initialResult.meta.pagination;
    const allRequests = [];

    // Start vanaf pagina 2 omdat we pagina 1 al hebben
    for (let page = 2; page <= pageCount; page++) {
      const separator = endpoint.includes("?") ? "&" : "?";
      const pageUrl = `${BASE_URL_METAPI}/${endpoint}${separator}pagination[page]=${page}`;
      allRequests.push(fetch(pageUrl, options).then((res) => res.json()));
    }

    const allResponses = await Promise.all(allRequests);

    // Combineer alle resultaten
    const combinedData = {
      data: [...initialResult.data],
      meta: initialResult.meta,
    };

    allResponses.forEach((response) => {
      if (response.data) {
        combinedData.data = [...combinedData.data, ...response.data];
      }
    });

    // Update total count in meta
    combinedData.meta.pagination = {
      ...combinedData.meta.pagination,
      total: combinedData.data.length,
    };

    if (method === "GET") {
      cache.set(endpoint, {
        data: combinedData,
        timestamp: Date.now(),
      });
    }

    return combinedData;
  } catch (error) {
    console.error("Error in fetcher2:", error);
    throw error;
  }
};

// Legacy fetcher voor backward compatibility
const fetcher = async (endpoint, method = "GET", data = null) => {
  console.warn(
    "Warning: Consider migrating to fetcher2 for consistent API key authentication"
  );
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const jwtToken = localStorage.getItem("jwt");
  if (jwtToken) {
    options.headers["Authorization"] = `Bearer ${jwtToken}`;
  }

  if (data) {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, options);
    const jsonData = await response.json();
    if (!response.ok) {
      throw new Error(
        jsonData.message || "Er ging iets mis met de API-aanroep."
      );
    }
    return jsonData;
  } catch (error) {
    throw error;
  }
};

// Helper functie voor geoptimaliseerde afbeeldingen
export const getOptimizedImageUrl = (url, options = {}) => {
  if (!url) return "";

  const baseUrl = url.startsWith("http") ? "" : BASE_URL;
  let finalUrl = `${baseUrl}${url}`;

  // Voeg image optimalisatie parameters toe indien beschikbaar
  const { width, height, quality = 80 } = options;
  if (width || height) {
    finalUrl += "?";
    if (width) finalUrl += `width=${width}&`;
    if (height) finalUrl += `height=${height}&`;
    finalUrl += `quality=${quality}`;
  }

  return finalUrl;
};

// Bestaande exports behouden
export const getUser = (userId) => fetcher2(`users/${userId}`);
export const getItems = () => fetcher2("items?populate=image");
export const loginUser = (email, password) =>
  fetcher2("auth/local", "POST", { identifier: email, password });
export const fetchOrdersForUser = (email) =>
  fetcher2(`orders?filters[email][$eq]=${email}`);
export const fetchWebshopOrdersForUser = (email) =>
  fetcher2(`webshop-orders?filters[email][$eq]=${email}`);

export { BASE_URL };
