// App.js
import { lazy, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";

// Direct importeren van kritische componenten
import CookieWall from "./components/CookieWall";
import { AuthProvider } from "./AuthContext";
import AuthModal from "./components/AuthModal";
import Navbar from "./scenes/global/Navbar";
import Footer from "./scenes/global/Footer";

// Lazy loading voor niet-kritische componenten
const Home = lazy(() => import("./scenes/home/Home"));
const AboutUs = lazy(() => import("./components/AboutUs"));
const ItemDetails = lazy(() => import("./scenes/itemDetails/ItemDetails"));
const CartMenu = lazy(() => import("./scenes/global/CartMenu"));
const Checkout = lazy(() => import("./scenes/checkout/Checkout"));
const Checkout2 = lazy(() => import("./scenes/checkout/Checkout2"));
const Confirmation = lazy(() => import("./scenes/checkout/Confirmation"));
const Confirmation2 = lazy(() => import("./scenes/checkout/Confirmation2"));
const Products = lazy(() => import("./scenes/products/Products"));
const BuurtPakketten = lazy(() =>
  import("./scenes/buurtpakketten/BuurtPakketten")
);
const Products2 = lazy(() => import("./scenes/products/Products2"));
const Favorites = lazy(() => import("./scenes/favorites/Favorites"));
const ResumeOrderPage = lazy(() => import("./scenes/ResumeOrderPage"));
const SearchResults = lazy(() =>
  import("./scenes/searchResults/SearchResults")
);
const PrivacyPolicy = lazy(() => import("./scenes/PrivacyPolicy"));
const VerkoopVoorwaarden = lazy(() => import("./scenes/VerkoopVoorwaarden"));
const OrdersPage = lazy(() => import("./components/OrdersPage"));
const ContactPage = lazy(() => import("./components/ContactPage"));

const ProfilePage = lazy(() => import("./components/ProfilePage"));
const Veiligheidsinstructies = lazy(() =>
  import("./components/VeiligheidsInstructies")
);
const GratisVuurwerk = lazy(() => import("./components/GratisVuurwerk"));
const CookieSettingsModal = lazy(() =>
  import("./components/CookieSettingsModal")
);

// Preload functies voor kritische routes
const preloadHome = () => import("./scenes/home/Home");
const preloadProducts = () => import("./scenes/products/Products");

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

// LoadingSpinner component
const LoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100px",
      padding: "20px",
    }}
  >
    <div
      style={{
        width: "40px",
        height: "40px",
        border: "3px solid #f3f3f3",
        borderTop: "3px solid #e3197e",
        borderRadius: "50%",
        animation: "spin 1s linear infinite",
        opacity: "0.8",
      }}
    />
  </div>
);

// Functie om URL-redirects te controleren en uit te voeren
const checkAndRedirectToNewSearch = () => {
  const currentURL = window.location.href;
  if (currentURL.includes("/catalogsearch/result/")) {
    const url = new URL(currentURL);
    const searchParams = url.searchParams.get("q");
    if (searchParams) {
      const newURL = `/search?query=${searchParams.replace(/\+/g, "%20")}`;
      window.history.replaceState(null, null, newURL);
      window.location.reload();
    }
  }
};

// Redirect component voor /items/:itemId naar /item/:itemId
const RedirectItemsToItem = () => {
  const { itemId } = useParams();
  return <Navigate to={`/item/${itemId}`} replace />;
};

function App({ isDarkMode, setIsDarkMode }) {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isCookieSettingsOpen, setCookieSettingsOpen] = useState(false);

  // Preload kritische routes na initial load
  useEffect(() => {
    const timer = setTimeout(() => {
      preloadHome();
      preloadProducts();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    checkAndRedirectToNewSearch();
  }, []);

  return (
    <div className="app">
      <AuthProvider>
        <BrowserRouter>
          <ScrollToTop />

          {/* Kritische UI componenten direct renderen */}
          <Navbar
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
            onAuthClick={() => setIsAuthModalOpen(true)}
          />
          <AuthModal
            isOpen={isAuthModalOpen}
            onClose={() => setIsAuthModalOpen(false)}
          />

          {/* Content met Suspense */}
          <main>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                {/* Redirect routes */}
                <Route
                  path="/bruchemvuurwerk/assortiment"
                  element={<Navigate to="/products" replace />}
                />
                <Route
                  path="/bruchemvuurwerk/overons-dealer"
                  element={<Navigate to="/about" replace />}
                />
                <Route
                  path="/bruchemvuurwerk/bestel-informatie-dealer"
                  element={<Navigate to="/verkoopvoorwaarden" replace />}
                />
                <Route
                  path="/bruchemvuurwerk/*"
                  element={<Navigate to="/" replace />}
                />

                {/* Standaard routes */}
                <Route
                  path="/verkoopvoorwaarden"
                  element={<VerkoopVoorwaarden />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/" element={<Home />} />

                {/* Dubbele routes voor item/itemId */}
                <Route path="/item/:itemId" element={<ItemDetails />} />
                <Route
                  path="/items/:itemId"
                  element={<RedirectItemsToItem />}
                />

                <Route path="checkout" element={<Checkout />} />
                <Route path="checkout2" element={<Checkout2 />} />
                <Route
                  path="checkout/success/:webshopOrderNumber?"
                  element={<Confirmation />}
                />
                <Route
                  path="checkout/success2/:sessionId?"
                  element={<Confirmation2 />}
                />
                <Route path="/shoppinglist" element={<Home />} />
                <Route path="/products" element={<Products />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/products2" element={<Products2 />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/search" element={<SearchResults />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route
                  path="/resume-order/:orderId"
                  element={<ResumeOrderPage />}
                />
                <Route path="/orders" element={<OrdersPage />} />
                <Route
                  path="/veiligheidsinstructies"
                  element={<Veiligheidsinstructies />}
                />
                <Route path="/gratis-vuurwerk" element={<GratisVuurwerk />} />
                <Route path="/buurtpakketten" element={<BuurtPakketten />} />
                <Route path="/contact" element={<ContactPage />} />
              </Routes>
            </Suspense>
          </main>

          {/* Niet-kritische componenten met eigen Suspense */}
          <Suspense fallback={null}>
            <CartMenu />
          </Suspense>

          <Footer onCookieSettingsClick={() => setCookieSettingsOpen(true)} />

          <Suspense fallback={null}>
            <CookieSettingsModal
              isOpen={isCookieSettingsOpen}
              onClose={() => setCookieSettingsOpen(false)}
            />
          </Suspense>
        </BrowserRouter>
      </AuthProvider>
      <CookieWall onSettingsClick={() => setCookieSettingsOpen(true)} />
    </div>
  );
}

export default App;
