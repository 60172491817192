// src/state/index.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCartOpen: false,
  cart: [],
  items: [],
  stockAlerts: {}, // Voeg dit toe
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },

    addToCart: (state, action) => {
      const existingItemIndex = state.cart.findIndex(
        (item) => item.id === action.payload.item.id
      );
      if (existingItemIndex !== -1) {
        state.cart[existingItemIndex].count += action.payload.item.count;
      } else {
        state.cart = [...state.cart, action.payload.item];
      }
    },

    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload.id);
      // Verwijder ook eventuele stock alert voor dit item
      delete state.stockAlerts[action.payload.id];
    },

    increaseCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.id) {
          item.count++;
        }
        return item;
      });
    },

    decreaseCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.id && item.count > 1) {
          item.count--;
        }
        return item;
      });
    },

    setCount: (state, action) => {
      console.log("Setting count for item. Current state of cart:", state.cart);
      console.log("Payload:", action.payload);
      const existingItemIndex = state.cart.findIndex(
        (item) => item.id === action.payload.id
      );
      if (existingItemIndex !== -1) {
        state.cart[existingItemIndex].count = action.payload.count;

        // Verwijder de stock alert als het nieuwe aantal lager is dan of gelijk is aan de beschikbare voorraad
        const itemInStock = state.items.find(
          (item) => item.id === action.payload.id
        );
        if (itemInStock && action.payload.count <= itemInStock.stock) {
          delete state.stockAlerts[action.payload.id];
        }
      }
    },

    clearCart: (state) => {
      state.cart = [];
      state.stockAlerts = {}; // Voeg dit toe
    },

    setIsCartOpen: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },

    // Voeg deze reducers toe
    setStockAlerts: (state, action) => {
      state.stockAlerts = { ...state.stockAlerts, ...action.payload };
    },

    clearStockAlert: (state, action) => {
      const { id } = action.payload;
      delete state.stockAlerts[id];
    },
  },
});

export const {
  setItems,
  addToCart,
  removeFromCart,
  increaseCount,
  decreaseCount,
  setIsCartOpen,
  clearCart, // Added clearCart action
  setCount,
  setStockAlerts, // Nieuwe action
  clearStockAlert, // Nieuwe action
} = cartSlice.actions;

export default cartSlice.reducer;
