import React, { useState, useEffect } from "react";
import { Button, Box, Typography, Link as MuiLink, Modal } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CookieWall = ({ onSettingsClick }) => {
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const accepted = localStorage.getItem("cookiesAccepted");
    if (accepted !== "true") {
      setShow(true);
    }

    const updateDeviceType = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    updateDeviceType();
    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    localStorage.setItem("analyticsAccepted", "true");
    setShow(false);
  };

  const rejectCookies = () => {
    setShow(false);
  };

  const cookieContent = (
    <>
      <Typography variant="body1" sx={{ margin: 0 }}>
        Deze website gebruikt functionele en analytische cookies voor een betere
        gebruikerservaring. Door op 'Ja' te klikken, gaat u akkoord met het
        gebruik van deze cookies voor verkoopgerelateerde activiteiten. Meer
        informatie vindt u in onze{" "}
        <MuiLink
          href="/privacy-policy"
          sx={{
            color: isMobile
              ? theme.palette.primary.main
              : "var(--cookiewall-link-color)",
            textDecoration: "underline",
          }}
        >
          privacyverklaring
        </MuiLink>
        .
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={acceptCookies}
          sx={{
            margin: "5px",
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              border: `1px solid ${theme.palette.primary.dark}`,
              boxShadow: "none",
            },
          }}
        >
          Ja
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={rejectCookies}
          sx={{
            margin: "5px",
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "rgba(25, 118, 210, 0.08)",
              border: `1px solid ${theme.palette.primary.dark}`,
              boxShadow: "none",
            },
          }}
        >
          Nee
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={onSettingsClick}
          sx={{
            margin: "5px",
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "rgba(25, 118, 210, 0.08)",
              border: `1px solid ${theme.palette.primary.dark}`,
              boxShadow: "none",
            },
          }}
        >
          Cookie-instellingen
        </Button>
      </Box>
    </>
  );

  if (isMobile) {
    return (
      <Modal
        open={show}
        aria-labelledby="cookie-modal-title"
        aria-describedby="cookie-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "90%",
            maxWidth: "500px",
            maxHeight: "90vh",
            overflowY: "auto",
            borderRadius: "8px",
            margin: "20px",
          }}
        >
          {cookieContent}
        </Box>
      </Modal>
    );
  }

  return (
    <>
      {show && <div style={{ height: "80px" }} />}
      {show && (
        <Box
          component="div"
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "var(--cookiewall-bg-color)",
            color: "var(--cookiewall-text-color)",
            padding: "10px",
            textAlign: "center",
            zIndex: 1000,
            height: "80px",
            boxSizing: "border-box",
            transition: "height 0.3s ease-in-out",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {cookieContent}
        </Box>
      )}
    </>
  );
};

export default CookieWall;
