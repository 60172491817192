import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  IconButton,
  Box,
  Typography,
  Badge,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import PersonOutline from "@mui/icons-material/PersonOutline";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ShoppingBagOutlined from "@mui/icons-material/ShoppingBagOutlined";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import GroupsIcon from "@mui/icons-material/Groups";
import EmailIcon from "@mui/icons-material/Email";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { removeFromCart, setIsCartOpen, setCount } from "../../state";
import { useAuth } from "../../AuthContext";
import DarkModeIcon from "../../assets/svg/dark_mode_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg";
import LightModeIcon from "../../assets/svg/light_mode_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg";
import { shades } from "../../theme";
import { fetcher2 } from "../../api";

// Animated Menu Icon Component
const AnimatedMenuIcon = ({ isOpen }) => (
  <Box
    sx={{
      width: 24,
      height: 24,
      position: "relative",
      transform: isOpen ? "rotate(180deg) scale(0.95)" : "rotate(0) scale(1)",
      transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      ":hover": {
        transform: isOpen
          ? "rotate(180deg) scale(1.05)"
          : "rotate(0) scale(1.1)",
      },
    }}
  >
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: 2,
        background: "currentColor",
        top: "50%",
        left: 0,
        transform: isOpen ? "rotate(45deg)" : "translateY(-6px)",
        transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: 2,
        background: "currentColor",
        top: "50%",
        left: 0,
        opacity: isOpen ? 0 : 1,
        transition: "opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: 2,
        background: "currentColor",
        top: "50%",
        left: 0,
        transform: isOpen ? "rotate(-45deg)" : "translateY(6px)",
        transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      }}
    />
  </Box>
);

function Navbar({ onAuthClick, isDarkMode, setIsDarkMode }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const { isLoggedIn } = useAuth();
  const { logout } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchBoxRef = useRef(null);
  const menuRef = useRef(null);
  const theme = useTheme();
  const isNonMobileScreensSearchBar = useMediaQuery("(min-width:1000px)");
  const isMobile = !useMediaQuery("(min-width:600px)");
  const isProductsPage = location.pathname === "/products";
  const [openingHours, setOpeningHours] = useState([]);
  const [contactOptions, setContactOptions] = useState({
    phone: "",
    mail: "",
    whatsapp: "",
    address: "",
    maps: "",
  });

  const searchInputRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutAndRedirect = () => {
    setTimeout(() => {
      logout();
      navigate("/");
    }, 500);
  };

  const toggleSearch = () => {
    if (isNonMobileScreensSearchBar) {
      if (isSearchOpen && searchQuery.trim() === "") {
        setIsSearchOpen(false);
      } else {
        setIsSearchOpen(true);
        setTimeout(() => {
          if (searchInputRef.current) {
            searchInputRef.current.focus();
          }
        }, 300);
      }
    } else {
      setIsSearchOpen((prev) => !prev);
    }
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim() !== "") {
      navigate(`/search?query=${searchQuery}`);
      setSearchQuery("");
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    if (anchorEl) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [anchorEl]);

  useEffect(() => {
    if (isSearchOpen) {
      document.body.classList.add("search-open-margin");
    } else {
      document.body.classList.remove("search-open-margin");
    }
  }, [isSearchOpen]);

  useEffect(() => {
    setIsSearchOpen(false);
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    document.body.classList.toggle("darkmode", isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetcher2("configuration-variables");

        // Filter openingHours
        const hoursData = response.data.filter(
          (item) => item.attributes.type === "openingHours"
        );
        const sortedHours = hoursData.sort((a, b) => {
          const dayA = parseInt(a.attributes.value.split(" ")[0], 10);
          const dayB = parseInt(b.attributes.value.split(" ")[0], 10);
          return dayA - dayB;
        });
        setOpeningHours(sortedHours);

        // Filter contactOptions
        const contactData = response.data.filter(
          (item) => item.attributes.type === "contactOptions"
        );
        const contactObj = {
          phone: "",
          mail: "",
          whatsapp: "",
          address: "",
          maps: "",
        };

        contactData.forEach((item) => {
          const { subValue, value } = item.attributes;
          if (contactObj.hasOwnProperty(subValue)) {
            contactObj[subValue] = value;
          }
        });

        setContactOptions(contactObj);
      } catch (error) {
        console.error("Error fetching navbar data:", error);
      }
    };

    fetchData();
  }, []);

  const removeOutOfStockItemsFromCart = async () => {
    try {
      const itemsData = await fetcher2("items");

      for (let cartItem of cart) {
        const item = itemsData.data.find(
          (apiItem) => apiItem.id === cartItem.id
        );

        if (item && item.attributes.stockQuantity !== null) {
          if (item.attributes.stockQuantity === 0) {
            dispatch(removeFromCart({ id: cartItem.id }));
          } else {
            if (
              item.attributes.maxPerOrder !== null &&
              cartItem.count > item.attributes.maxPerOrder
            ) {
              dispatch(
                setCount({
                  id: cartItem.id,
                  count: item.attributes.maxPerOrder,
                })
              );
            } else if (cartItem.count > item.attributes.stockQuantity) {
              dispatch(
                setCount({
                  id: cartItem.id,
                  count: item.attributes.stockQuantity,
                })
              );
            }
          }
        }
      }
    } catch (error) {
      console.error("Error processing stock information:", error);
    }
  };

  const handleCartClick = () => {
    removeOutOfStockItemsFromCart();
    dispatch(setIsCartOpen({}));
  };

  // Mobile Sidebar Component
  const MobileSidebar = () => (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: sidebarOpen ? 0 : "-100%",
        width: "80%",
        height: "100vh",
        backgroundColor: theme.palette.background.paper,
        transition:
          "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), left 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        transform: sidebarOpen
          ? "translateX(0) scale(1)"
          : "translateX(-10px) scale(0.98)",
        transformOrigin: "left center",
        opacity: sidebarOpen ? 1 : 0.95,
        zIndex: 1200,
        boxShadow: sidebarOpen ? "4px 0px 10px rgba(0,0,0,0.2)" : "none",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      {/* Sidebar Header */}
      <Box
        sx={{
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
        }}
      >
        <Typography variant="h6">Menu</Typography>
        <IconButton onClick={() => setSidebarOpen(false)}>
          <AnimatedMenuIcon isOpen={false} />
        </IconButton>
      </Box>

      {/* Sidebar Content */}
      <Box
        sx={{
          p: 2,
          flex: 1,
          overflowY: "auto",
        }}
      >
        {/* Navigation Links */}
        <Box sx={{ mb: 3 }}>
          {/* Home Link */}
          <Box
            component={Link}
            to="/"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              textDecoration: "none",
              color: theme.palette.text.primary,
              transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
              ":hover": {
                transform: "translateX(8px)",
                "& svg": {
                  transform: "scale(1.1)",
                },
              },
            }}
            onClick={() => setSidebarOpen(false)}
          >
            <HomeOutlinedIcon sx={{ mr: 2 }} />
            <Typography>Home</Typography>
          </Box>

          {/* Assortiment Link */}
          <Box
            component={Link}
            to="/products"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              textDecoration: "none",
              color: theme.palette.text.primary,
              transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
              ":hover": {
                transform: "translateX(8px)",
                "& svg": {
                  transform: "scale(1.1)",
                },
              },
            }}
            onClick={() => setSidebarOpen(false)}
          >
            <LocalFireDepartmentIcon sx={{ mr: 2 }} />
            <Typography>Assortiment</Typography>
          </Box>

          {/* Contact Link */}
          <Box
            component={Link}
            to="/contact"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              textDecoration: "none",
              color: theme.palette.text.primary,
              transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
              ":hover": {
                transform: "translateX(8px)",
                "& svg": {
                  transform: "scale(1.1)",
                },
              },
            }}
            onClick={() => setSidebarOpen(false)}
          >
            <EmailIcon sx={{ mr: 2 }} />
            <Typography>Contact</Typography>
          </Box>

          {/* Gratis Vuurwerk Link */}
          <Box
            component={Link}
            to="/gratis-vuurwerk"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              textDecoration: "none",
              color: theme.palette.text.primary,
              transition: "all 0.2s",
              ":hover": {
                transform: "translateX(8px)",
                "& svg": {
                  transform: "scale(1.1)",
                },
              },
            }}
            onClick={() => setSidebarOpen(false)}
          >
            <CardGiftcardIcon sx={{ mr: 2 }} />
            <Typography>Gratis Vuurwerk</Typography>
          </Box>

          {/* Buurtpakketten Link */}
          <Box
            component={Link}
            to="/buurtpakketten"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              textDecoration: "none",
              color: theme.palette.text.primary,
              transition: "all 0.2s",
              ":hover": {
                transform: "translateX(8px)",
                "& svg": {
                  transform: "scale(1.1)",
                },
              },
            }}
            onClick={() => setSidebarOpen(false)}
          >
            <GroupsIcon sx={{ mr: 2 }} />
            <Typography>Buurtpakketten</Typography>
          </Box>

          {/* Favorieten Link */}
          <Box
            component={Link}
            to="/favorites"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              textDecoration: "none",
              color: theme.palette.text.primary,
              transition: "all 0.2s",
              ":hover": {
                transform: "translateX(8px)",
                "& svg": {
                  transform: "scale(1.1)",
                },
              },
            }}
            onClick={() => setSidebarOpen(false)}
          >
            <FavoriteBorder sx={{ mr: 2 }} />
            <Typography>Favorieten</Typography>
          </Box>
        </Box>

        {/* Contact Information */}
        <Box
          sx={{
            mt: "auto",
            pt: 3,
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {/* Opening Hours */}
          <Typography
            variant="h6"
            sx={{ mb: 2, display: "flex", alignItems: "center" }}
          >
            <AccessTimeIcon sx={{ mr: 1 }} />
            Openingstijden
          </Typography>
          <Box sx={{ ml: 4, mb: 3 }}>
            {openingHours.map((item) => {
              const text = item.attributes.value;
              const parts = text.split(" ");
              const day = parts[0];
              const rest = text.replace(`${day} `, "");
              const labelPart = rest.split(":")[0];
              const timePart = rest.substring(rest.indexOf(":") + 1);

              return (
                <Typography variant="body2" sx={{ mb: 1 }} key={item.id}>
                  <strong>
                    {day} {labelPart}:
                  </strong>
                  {timePart}
                </Typography>
              );
            })}
          </Box>

          {/* Contact Details */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            Contact
          </Typography>
          <Box sx={{ ml: 4 }}>
            {contactOptions.phone && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <PhoneIcon sx={{ mr: 1, fontSize: "1rem" }} />
                <Typography
                  variant="body2"
                  component="a"
                  href={`tel:${contactOptions.phone}`}
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {contactOptions.phone}
                </Typography>
              </Box>
            )}

            {contactOptions.whatsapp && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <WhatsAppIcon sx={{ mr: 1, fontSize: "1rem" }} />
                <Typography
                  variant="body2"
                  component="a"
                  href={contactOptions.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  WhatsApp
                </Typography>
              </Box>
            )}

            {contactOptions.mail && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <EmailIcon sx={{ mr: 1, fontSize: "1rem" }} />
                <Typography
                  variant="body2"
                  component="a"
                  href={`mailto:${contactOptions.mail}`}
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {contactOptions.mail}
                </Typography>
              </Box>
            )}

            {contactOptions.address && contactOptions.maps && (
              <Box sx={{ display: "flex", alignItems: "start", mb: 1 }}>
                <LocationOnIcon sx={{ mr: 1, fontSize: "1rem", mt: "3px" }} />
                <Typography variant="body2">
                  <a
                    href={contactOptions.maps}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {contactOptions.address}
                  </a>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // Backdrop Component
  const Backdrop = () => (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1100,
        opacity: sidebarOpen ? 1 : 0,
        visibility: sidebarOpen ? "visible" : "hidden",
        transition:
          "opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      }}
      onClick={() => setSidebarOpen(false)}
    />
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="60px"
        backgroundColor={theme.palette.background.default}
        color={theme.palette.text.primary}
        position="fixed"
        top="0"
        left="0"
        zIndex="1"
      >
        <Box
          width="80%"
          margin="auto"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            {isMobile ? (
              <IconButton
                aria-label="Menu"
                sx={{ color: theme.palette.text.primary }}
                onClick={() => setSidebarOpen(true)}
              >
                <AnimatedMenuIcon isOpen={sidebarOpen} />
              </IconButton>
            ) : (
              <IconButton
                component={Link}
                to="/"
                aria-label="Home"
                sx={{ color: theme.palette.text.primary }}
              >
                <HomeOutlinedIcon
                  className="vuurwerk-bruchem-icon"
                  sx={{
                    fontSize: "22px",
                    verticalAlign: "middle",
                  }}
                />
              </IconButton>
            )}
            <Typography
              component={Link}
              to="/"
              variant="h3title"
              color={shades.secondary[400]}
              className="vuurwerk-bruchem-text"
              sx={{
                ml: 0,
                display: { xs: "none", md: "block" },
                textDecoration: "none",
              }}
              aria-label="Home"
            >
              VUURWERK BRUCHEM
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" columnGap="10px">
            {isNonMobileScreensSearchBar ? (
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              >
                {isSearchOpen ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "250px",
                      opacity: 1,
                      transition:
                        "width 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s",
                      overflow: "hidden",
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: "25px",
                      paddingLeft: "10px",
                    }}
                  >
                    <TextField
                      inputRef={searchInputRef}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchSubmit();
                        }
                      }}
                      placeholder="Zoeken..."
                      variant="outlined"
                      sx={{
                        flexGrow: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "25px",
                          paddingRight: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          color: theme.palette.text.primary,
                          fontSize: "18px",
                          padding: "10px 16px",
                          height: "40px",
                          boxSizing: "border-box",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      aria-label="Zoekveld"
                    />
                    <IconButton
                      onClick={toggleSearch}
                      sx={{
                        color: theme.palette.text.primary,
                        padding: "0",
                        height: "40px",
                        width: "40px",
                      }}
                      aria-label="Sluit Zoekveld"
                    >
                      <SearchOutlined sx={{ fontSize: "24px" }} />
                    </IconButton>
                  </Box>
                ) : (
                  <IconButton
                    onClick={toggleSearch}
                    sx={{
                      color: theme.palette.text.primary,
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      transition: "all 0.3s",
                      backgroundColor: "transparent",
                    }}
                    aria-label="Open Zoekveld"
                  >
                    <SearchOutlined sx={{ fontSize: "24px" }} />
                  </IconButton>
                )}
              </Box>
            ) : (
              <IconButton
                sx={{ color: theme.palette.text.primary }}
                onClick={toggleSearch}
                aria-label="Zoeken"
              >
                <SearchOutlined />
              </IconButton>
            )}

            <IconButton
              onClick={() => navigate("/favorites")}
              sx={{ color: theme.palette.text.primary }}
              aria-label="Favorieten"
            >
              <FavoriteBorder />
            </IconButton>

            <IconButton
              onClick={() => setIsDarkMode(!isDarkMode)}
              sx={{
                color: theme.palette.text.primary,
                padding: 0,
                margin: 0,
                height: "40px",
                width: "40px",
              }}
              aria-label="Schakel Dark Mode in of uit"
            >
              <img
                src={isDarkMode ? LightModeIcon : DarkModeIcon}
                alt={
                  isDarkMode
                    ? "Schakel naar Light Mode"
                    : "Schakel naar Dark Mode"
                }
                style={{
                  width: "20px",
                  height: "20px",
                  filter: isDarkMode ? "invert(1)" : "none",
                }}
              />
            </IconButton>

            {isLoggedIn ? (
              <>
                <IconButton
                  sx={{
                    color: theme.palette.text.primary,
                    position: "relative",
                  }}
                  onClick={handleClick}
                  aria-label="Profiel"
                >
                  <PersonOutline />
                  <CheckCircle
                    style={{ color: shades.green }}
                    fontSize="small"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: 12,
                      height: 12,
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      position: "absolute",
                      top: "100%",
                      left: "0",
                      zIndex: 1000,
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                  ref={menuRef}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/orders");
                      handleClose();
                    }}
                  >
                    Mijn bestellingen
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/profile");
                      handleClose();
                    }}
                  >
                    Mijn gegevens
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logoutAndRedirect();
                      handleClose();
                    }}
                  >
                    Uitloggen
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <IconButton
                sx={{ color: theme.palette.text.primary, position: "relative" }}
                onClick={onAuthClick}
                aria-label="Inloggen"
              >
                <PersonOutline />
              </IconButton>
            )}

            <Badge
              badgeContent={cart.length}
              color="secondary"
              invisible={cart.length === 0}
              sx={{
                "& .MuiBadge-badge": {
                  right: 5,
                  top: 5,
                  padding: "0 4px",
                  height: "14px",
                  minWidth: "13px",
                  backgroundColor: shades.secondary[400],
                  color:
                    theme.palette.mode === "light"
                      ? "#FFFFFF"
                      : theme.palette.text.primary,
                },
              }}
            >
              <IconButton
                onClick={handleCartClick}
                sx={{ color: theme.palette.text.primary }}
                aria-label="Winkelwagen"
              >
                <ShoppingBagOutlined />
              </IconButton>
            </Badge>
          </Box>
        </Box>

        {!isNonMobileScreensSearchBar && isSearchOpen && (
          <Box ref={searchBoxRef} className="customSearchBox">
            <TextField
              className="customSearchField"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchSubmit();
                }
              }}
              autoFocus
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                "& .MuiInputBase-input": {
                  color: theme.palette.text.primary,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.divider,
                },
              }}
              aria-label="Zoekveld"
            />
            <IconButton
              onClick={handleSearchSubmit}
              sx={{ color: theme.palette.text.primary }}
              aria-label="Zoekopdracht Verzenden"
            >
              <SearchOutlined />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Render sidebar en backdrop op mobile */}
      {isMobile && sidebarOpen && <MobileSidebar />}
      {isMobile && sidebarOpen && <Backdrop />}
    </>
  );
}

export default Navbar;
